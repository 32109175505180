<template>
  <main class="our-works">
    <b-container>
      <b-row>
        <b-col cols="12">
          <h1 class="our-works__title">
            {{ $t("heading.our_works_title") }}
          </h1>
        </b-col>
      </b-row>
      <b-row v-if="items.length > 0">
        <b-col
          class="mb-4"
          cols="12"
          md="6"
          lg="3"
          v-for="(item, index) in items"
          :key="index"
        >
          <OurWorkItem :item="item" class="our-works__item" :isOurWork="true" />
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col
          class="mb-3"
          cols="12"
          md="6"
          lg="3"
          v-for="(num, index) in 8"
          :key="index"
        >
          <content-placeholders :rounded="true" :centered="true">
            <content-placeholders-img style="height: 200px" />
          </content-placeholders>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>
<script>
import { IndexData } from "@/helpers/apiMethods";
export default {
  name: "OurWorks",
  components: {
    OurWorkItem: () => import("@/components/OurWork/OuerWork-item/index.vue")
  },
  data() {
    return {
      items: []
    };
  },
  created() {
    this.handleGetWorks();
  },
  methods: {
    async handleGetWorks() {
      try {
        const {
          data: { works }
        } = await IndexData({ reqName: "works" });
        this.items = works;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
